import type { RegisterOptions } from 'react-hook-form'

const nameRegexp = new RegExp(/[^\p{L}']/u)

export const getAge = (DOB: string): number | null => {
  if (!/^\d{2}-\d{2}-\d{4}$/.test(DOB)) {
    return null
  }

  const [day, month, year] = DOB.split('-').map(Number)
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return null
  }

  const birthDate = new Date(Date.UTC(year, month - 1, day))
  const today = new Date()
  if (birthDate.getUTCFullYear() !== year || birthDate.getUTCMonth() !== month - 1 || birthDate.getUTCDate() !== day) {
    return null
  }

  let age = today.getUTCFullYear() - birthDate.getUTCFullYear()
  const m = today.getUTCMonth() - birthDate.getUTCMonth()
  if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
    age--
  }

  return age
}

export const isValidDate = (text: string): boolean => {
  if (!/^\d{2}-\d{2}-\d{4}$/.test(text)) {
    return false
  }

  const [d, m, y] = text.split('-').map(Number)
  if (isNaN(d) || isNaN(m) || isNaN(y)) {
    return false
  }

  const date = new Date(Date.UTC(y, m - 1, d))
  return date.getUTCDate() === d && date.getUTCMonth() === m - 1 && date.getUTCFullYear() === y
}

export const isValidName = (str: string) => !nameRegexp.test(str) && str.length >= 2

export const isValidPhone = (str: string) => str.startsWith('07') && (str.length === 11 || str.length === 10)

export const stripTags = (str?: string) => str?.replace(/(<([^>]+)>)/gi, '')

export const emailValidation: RegisterOptions = {
  required: true,
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: 'Email is invalid'
  }
}

export const passwordValidation: RegisterOptions = {
  required: true,
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters long'
  },
  validate: {
    upperCase: (value: string) => /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
    lowerCase: (value: string) => /[a-z]/.test(value) || 'Password must contain at least one lowercase letter',
    number: (value: string) => /[0-9]/.test(value) || 'Password must contain at least one number'
  }
}

export const phoneValidation: RegisterOptions = {
  // required: true,
  minLength: {
    value: 11,
    message: 'Phone number must be 11 characters long'
  },
  maxLength: {
    value: 11,
    message: 'Phone number must be 11 characters long'
  },
  validate: {
    startsWith07: (value: string) => !value || (value.startsWith('07') && /^\d+$/.test(value)) || 'Please enter a valid UK mobile number'
  }
}

export function escapeHTML(text: string) {
  return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
}
