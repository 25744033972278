'use client'
import { logViewMenu } from '@utils/analytics'
import { create } from 'zustand'
import { useRouter, useSearchParams } from 'next/navigation'

interface UIStore {
  isSidebarOpen: boolean
  toggleSidebar: () => void
  openSidebar: () => void
  closeSidebar: () => void
  isAppClosed: boolean
  setIsAppClosed: (isAppClosed: boolean) => void
}

export const useUIStore = create<UIStore>(set => {
  const getSearchParams = () => {
    if (typeof window === 'undefined') return new URLSearchParams()
    return new URLSearchParams(window.location.search)
  }

  return {
    isSidebarOpen: getSearchParams().has('menu'),
    isAppClosed: false,
    setIsAppClosed: isAppClosed => set({ isAppClosed }),
    toggleSidebar: () => {
      const params = getSearchParams()
      if (params.has('menu')) {
        params.delete('menu')
      } else {
        params.set('menu', '')
      }
      window.history.replaceState({}, '', `?${params.toString()}`)
      set(state => ({ isSidebarOpen: !state.isSidebarOpen }))
    },
    openSidebar: () => {
      const params = getSearchParams()
      params.set('menu', '')
      window.history.replaceState({}, '', `?${params.toString()}`)
      set({ isSidebarOpen: true })
      logViewMenu()
    },
    closeSidebar: () => {
      const params = getSearchParams()
      params.delete('menu')
      window.history.replaceState({}, '', `?${params.toString()}`)
      set({ isSidebarOpen: false })
    }
  }
})

export const useUIStoreActions = () => {
  const router = useRouter()
  const search = useSearchParams()
  const focused = search.has('menu')

  const open = () => {
    const params = new URLSearchParams(search.toString())
    params.set('menu', '')
    router.push(`?${params.toString()}`)
  }

  const close = () => {
    const params = new URLSearchParams(search.toString())
    params.delete('menu')
    router.replace(`?${params.toString()}`)
  }

  return {
    isOpen: focused,
    open,
    close
  }
}
